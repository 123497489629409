import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Article from '../components/article/index'
import Pagination from '../components/article/pagination'

import SEOPage from '../components/SEO/Page'

class ArticlesPage extends React.Component {
  render() {
    const { data, location, pageContext } = this.props
    const page = data.prismicNewsIndex.data
    const posts = data.allPrismicNewsArticle.edges

    return (
      <Layout location={location}>
        <SEOPage title={page.title && page.title} location={location} />
        <div className="mt-35 mb-32 lg:mb-36 mx-4 lg:mx-14">
          <div className="lg:flex lg:-mx-2">
            <div className="lg:w-1/4 flex flex-col justify-between lg:px-2">
              <h1 className="tracking-2xl mb-12 text-4xl font-light leading-tight text-grey">
                {page.title}
              </h1>
              <Pagination
                pageContext={pageContext}
                className="mb-12 text-2xl hidden lg:block"
              />
            </div>
            <div className="lg:w-3/4 text-lg leading-lg lg:px-2">
              <div className="md:flex flex-wrap md:-mx-2">
                {posts.map((item, i) => {
                  return (
                    <Article
                      key={i}
                      img={item.node.data.image.url}
                      alt={item.node.data.image.alt}
                      link={item.node.uid}
                      value={item.node.data.category.document[0].data.title}
                      title={item.node.data.title}
                      text={item.node.data.lead.text}
                      date={item.node.first_publication_date}
                    />
                  )
                })}
              </div>
            </div>
            <Pagination
              pageContext={pageContext}
              className="mb-12 text-2xl lg:hidden"
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageNewsQuery = graphql`
  query($skip: Int, $limit: Int) {
    prismicNewsIndex {
      data {
        title
      }
    }
    allPrismicNewsArticle(
      sort: { fields: data___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          uid
          first_publication_date(formatString: "Do MMMM YYYY")
          data {
            title
            lead {
              text
            }
            image {
              alt
              url
              localFile {
                childImageSharp {
                  id
                }
              }
            }
            body {
              html
            }
            category {
              uid
              document {
                data {
                  title
                  subtitle
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticlesPage
