import React from 'react'
import { Link } from 'gatsby'

const PreviousBtn = ({ previousPagePath }) => { 
  return (
    <div>
      <Link
        className="text-grey block mb-8 hover:opacity-75 focus:opacity-75 transition cursor-pointer"
        to={previousPagePath}
      >
        Previous page
      </Link>
    </div>
  )
}

const NextBtn = ({ nextPagePath }) => {
  return (
      <div>
        <Link
          className="text-grey block mb-8 hover:opacity-75 focus:opacity-75 transition cursor-pointer"
          direction="right"
          to={nextPagePath}
        >
          Next Page
        </Link>
      </div>
    )
}

const PagesLinks = ({ numberOfPages, pageNumber }) => {
  const pagesLinkArr = Array.from(Array(numberOfPages).keys())
  return pagesLinkArr.map(pageIndex => {
    const isActive = pageNumber === pageIndex
    const path = pageIndex === 0 ? '/news' : `/news/${pageIndex + 1}`
    const renderHumanPageNumber = pageIndex + 1
    return (
      <li key={renderHumanPageNumber} className="px-2">
        {isActive ? (
          <span className="text-grey inline no-underline font-extrabold">
            {renderHumanPageNumber}
          </span>
        ) : (
          <Link
            className="text-grey inline no-underline hover:opacity-75 focus:opacity-75 transition cursor-pointer"
            to={path}
          >
            {renderHumanPageNumber}
          </Link>
        )}
      </li>
    )
  })
}

const Pagination = ({ pageContext, className }) => (
  <>
  { pageContext.numberOfPages > 1 && 
  <div className={`mb-12 text-2xl ${className}`}>
    <ul className="flex list-reset -mx-2 mb-8">
      <PagesLinks {...pageContext} />
    </ul>
    <PreviousBtn {...pageContext} />
    <NextBtn {...pageContext} />
  </div>
  }  
</>
)

export default Pagination
